import styled from '@emotion/styled';
import { colors } from '../../utils/styleguide';

export type BackgroundImage =
  | string
  | {
      landscape?: string;
      portrait?: string;
    };

type SectionBackgroundProps = {
  backgroundColor?: string;
  backgroundImage?: BackgroundImage;
};

const SectionBackground = styled.section`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ backgroundColor }: SectionBackgroundProps) =>
    `background-color: ${backgroundColor || colors.white};`}

  ${({ backgroundImage }: SectionBackgroundProps) => {
    if (!backgroundImage) {
      return;
    }

    let backgroundImageUrl: string | undefined;

    if (typeof backgroundImage === 'string') {
      backgroundImageUrl = backgroundImage;
    } else {
      if (backgroundImage.landscape && backgroundImage.portrait) {
        return `
        background-image: url(${backgroundImage.portrait});

      @media (min-aspect-ratio: 4/3) {
        background-image: url(${backgroundImage.landscape});

      }
      `;
      } else {
        backgroundImageUrl = backgroundImage.landscape || backgroundImage.portrait;
      }
    }

    if (!backgroundImageUrl) {
      return;
    }

    return `background-image: url(${backgroundImageUrl});`;
  }}
`;

export default SectionBackground;
